<template>
    <template-layout>
        <template slot="drawers">
            <dispatch-drawer
                v-model="showDispatchDrawer"
                :vehicle="selectedVehicle"
                :shippingItems="shippingItems"
            >
            </dispatch-drawer>
        </template>

        <template slot="print">
            <span class="headline">Despacho del {{ this.filters.date | moment('dddd D [de] MMMM') }}</span>

            <div v-for="(value, key) in shippingItemsByVehicle" :key="key" style="page-break-after: always" class="mt-4">
                <h2 class="subtitle-1 mb-4">Móvil {{ key }}</h2>

                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Envio</th>
                                <th>Pedido</th>
                                <th>Cliente</th>
                                <th>Total</th>
                                <th>Factura</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="item in value" :key="item.id">
                                <td>{{ item.id }}</td>
                                <td>{{ item.orderId }}</td>
                                <td>{{ getCustomer(getOrder(item.orderId).customer).fullName | capitalize }}</td>
                                <td>{{ getOrder(item.orderId).total | toCurrency }}</td>
                                <td v-if="getOrder(item.orderId).invoice">{{ getOrder(item.orderId).invoice.invoiceFormattedNumber }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Despacho del {{ this.filters.date | moment('dddd D [de] MMMM') }}</span>
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <v-menu
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon><v-icon>mdi-calendar-edit</v-icon></v-btn>
                            </template>

                            <v-date-picker
                                v-model="filters.date"
                                @input="dateMenu = false"
                                no-title
                                :first-day-of-week="1"
                                locale="es-AR"
                                event-color="green lighten-1"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">
                        <v-btn icon @click="onPrintSelected" class="d-none d-md-flex"><v-icon>mdi-printer</v-icon></v-btn>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <dispatch-list-table
                            :shippingItems="shippingItems"
                        >
                            <template v-slot:actions="{ vehicle }">
                                <v-btn text color="primary" @click="onDispatchSelected(vehicle)">
                                    <v-icon class="mr-2">mdi-truck-check-outline</v-icon><span class="d-none d-sm-flex">Despachar</span>
                                </v-btn>
                            </template>
                        </dispatch-list-table>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import DispatchListTable from './DispatchListTable';
    import DispatchDrawer from './DispatchDrawer';

    export default {
        components: {
            TemplateLayout,
            DispatchListTable,
            DispatchDrawer
        },

        data() {
            return {
                isLoading: false,
                isSaving: false,

                showDispatchDrawer: false,

                dateMenu: false,
                filters: {
                    date: this.$moment().format('YYYY-MM-DD')
                },
                orders: [],

                selectedVehicle: null,
                selectedOrders: [],
                shippingItems: []
            };
        },

        computed: {
            shippingItemsByVehicle() {
                let groups = this.shippingItems.reduce((rv, x) => {
                    if(x.route && x.route.vehicle) {
                        const group = x.route.vehicle.id;
                        (rv[group] = rv[group] || []).push(x);
                    }

                    return rv;
                }, {});

                return groups;
            }
        },

        watch: {
            filters: {
                handler(val) {
                    this.fetchOrders();
                },

                deep: true
            }
        },

        mounted() {
            this.fetchOrders();
        },

        methods: {
            getCustomer(id) {
                return this.$store.getters['customer/getById'](id);
            },

            getOrder(id) {
                return this.$store.getters['order/getById'](id);
            },

            onPrintSelected() {
                window.print();
            },

            async onDispatchSelected(vehicle) {
                this.selectedVehicle = vehicle;
                this.showDispatchDrawer = !this.showDispatchDrawer;
            },

            async fetchOrders() {
                this.isLoading = true;

                this.shippingItems = [];

                try {
                    let shipments = await this.$store.dispatch('shipments/fetchAll', {
                        date: this.filters.date,
                        state: 'state.packed'
                    });

                    let orderIds = shipments.map(s => this.$store.getters['shipments/getById'](s)).map(s => s.orderId);

                    await this.$store.dispatch('order/fetchMany', {
                        ids: orderIds,
                        fields: 'id,deliveryDate,total,customer,customer.id,customer.fullName,state,deliverySchedule.schedule.start,deliverySchedule.schedule.end,vehicle.id,vehicle.name,commentCount'
                    });

                    this.shippingItems = shipments.map(s => this.$store.getters['shipments/getById'](s));
                }
                finally {
                    this.isLoading = false;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
