<template>
    <div>
        <v-card v-bind:key="key" v-for="(value, key) in shippingItemsByVehicle" class="mb-7">
            <v-card-title class="body-1">
                <v-row no-gutters>
                    <v-col>
                        Móvil {{ key }} <span class="grey--text">({{ value.length }})</span>
                    </v-col>

                    <v-col class="text-end">
                        <slot name="actions" v-bind:vehicle="value[0].route.vehicle"></slot>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    class="elevation-0"
                    hide-default-header
                    hide-default-footer
                    :headers="headers"
                    :items="value"
                    :items-per-page="-1"
                    :loading="isLoading"
                    no-data-text="No hay pedidos para mostrar"
                    sort-by="id"
                    sort-desc
                    :mobile-breakpoint="0"
                >
                    <template v-slot:item.orderId="{ item }">
                        <v-icon class="mr-2 d-none d-sm-inline grey--text">mdi-cart-outline</v-icon>
                        {{ item.orderId }}
                    </template>

                    <template v-slot:item.customer="{ item }">
                        {{ getCustomer(getOrder(item.orderId).customer).fullName | capitalize }}
                    </template>

                    <template v-slot:item.id="{ item }">
                        {{ item.id }}
                    </template>

                    <template v-slot:item.state="{ item }">
                        <tui-shipping-state-chip :state="item.state"></tui-shipping-state-chip>
                    </template>

                    <template v-slot:item.total="{ item }">
                        {{ getOrder(item.orderId).total | toCurrency }}
                    </template>

                    <template v-slot:item.invoice="{ item }">
                        <a class="text-right" v-if="getOrder(item.orderId).invoice" @click="onInvoiceSelected(getOrder(item.orderId).invoice)">{{ getOrder(item.orderId).invoice.invoiceFormattedNumber }}</a>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import TuiShippingStateChip from '@/ui/TUIShippingStateChip';

    export default {
        components: {
            TuiShippingStateChip
        },

        data() {
            return {
                invoiceUrl: process.env.VUE_APP_TALLO_API_URL + '/api/invoices/',

                isLoading: false,
                headers: [
                    { text: 'Pedido #', value: 'orderId', sortable: false },
                    { text: 'Cliente', value: 'customer', sortable: false },
                    { text: 'Estado', value: 'state', sortable: false, align: 'center' },
                    { text: 'Importe', value: 'total', sortable: false, align: 'center' },
                    { text: 'Factura', value: 'invoice', sortable: false, align: 'end' }
                ]
            };
        },

        props: {
            shippingItems: Array
        },

        computed: {
            shippingItemsByVehicle() {
                let groups = this.shippingItems.reduce((rv, x) => {
                    if(x.route && x.route.vehicle) {
                        const group = x.route.vehicle.id;
                        (rv[group] = rv[group] || []).push(x);
                    }

                    return rv;
                }, {});

                return groups;
            }
        },

        watch: {
        },

        methods: {
            getCustomer(id) {
                return this.$store.getters['customer/getById'](id);
            },

            getOrder(id) {
                return this.$store.getters['order/getById'](id);
            },

            async onInvoiceSelected(invoice) {
                let data = await this.$api.getBlob('/api/invoices/' + invoice.id);

                var file = new Blob([data], { type: 'application/pdf' });
                var fileURL = window.URL.createObjectURL(file);
                window.open(fileURL);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-data-table {
        thead {
            tr:last-child {
                th {
                    border: none !important;
                }
            }
        }
    }

    .invoice {
        border: 1px solid red;
    }
</style>
