var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.shippingItemsByVehicle),function(value,key){return _c('v-card',{key:key,staticClass:"mb-7"},[_c('v-card-title',{staticClass:"body-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" Móvil "+_vm._s(key)+" "),_c('span',{staticClass:"grey--text"},[_vm._v("("+_vm._s(value.length)+")")])]),_c('v-col',{staticClass:"text-end"},[_vm._t("actions",null,{"vehicle":value[0].route.vehicle})],2)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.headers,"items":value,"items-per-page":-1,"loading":_vm.isLoading,"no-data-text":"No hay pedidos para mostrar","sort-by":"id","sort-desc":"","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 d-none d-sm-inline grey--text"},[_vm._v("mdi-cart-outline")]),_vm._v(" "+_vm._s(item.orderId)+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.getCustomer(_vm.getOrder(item.orderId).customer).fullName))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('tui-shipping-state-chip',{attrs:{"state":item.state}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getOrder(item.orderId).total))+" ")]}},{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [(_vm.getOrder(item.orderId).invoice)?_c('a',{staticClass:"text-right",on:{"click":function($event){_vm.onInvoiceSelected(_vm.getOrder(item.orderId).invoice)}}},[_vm._v(_vm._s(_vm.getOrder(item.orderId).invoice.invoiceFormattedNumber))]):_vm._e()]}}],null,true)})],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }