<template>
    <v-navigation-drawer
        v-model="active"
        disable-resize-watcher
        right
        temporary
        fixed
        width="600"
        style="z-index: 12"
    >
        <v-container class="pa-10" v-if="vehicle">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <span class="headline" style="white-space: nowrap">Despacho {{ vehicle.name }}</span>
                    </v-row>
                </v-col>
            </v-row>

            <transition :name="transitionName" mode="out-in">
                <v-row key="step1" v-if="step == DispatchStep.Initial">
                    <v-col cols="12" class="fill-height">
                        <div class="d-flex flex-column">
                            <div class="flex-grow-1">
                                <div class="flex-grow-1">
                                    <span class="subtitle-1 font-weight-medium"><v-icon class="mr-2">mdi-package-variant-closed</v-icon>Carga</span>

                                    <v-list dense class="t-info-list">
                                        <v-list-item>
                                            <v-list-item-content>Pedidos</v-list-item-content>
                                            <v-list-item-content>
                                                {{ shipmentsToDispatch.length }}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-row key="step2" v-else-if="step === DispatchStep.Complete">
                    <v-col cols="12" class="fill-height">
                        <div class="d-flex flex-column fill-height justify-space-between">
                            <div class="text-center">
                                <span class="title">Se ha despachado el móvil con éxito</span>

                                <v-icon class="extra-large" color="green">mdi-truck-check-outline</v-icon>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </transition>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4" v-if="step == DispatchStep.Initial">
                <v-spacer></v-spacer>

                <v-btn color="orange" dark block @click="onDispatchSelected" :loading="isSaving">Despachar</v-btn>
            </v-row>

            <v-row class="ma-4" v-if="step == DispatchStep.Complete">
                <v-spacer></v-spacer>

                <v-btn color="green" dark block @click="onBackSelected" :loading="isSaving">Continuar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    const DispatchStep = Object.freeze({
        Initial: 1,
        Complete: 2
    });

    export default {
        components: {
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            vehicle: Object,
            shippingItems: Array
        },

        data() {
            return {
                transitionName: 'slide-left',
                DispatchStep,
                step: DispatchStep.Initial,

                active: false,
                isSaving: false
            };
        },

        computed: {
            shipmentsToDispatch() {
                return this.shippingItems.filter(s => s.route.vehicle.id === this.vehicle.id);
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
                this.step = DispatchStep.Initial;
            }
        },

        methods: {
            getOrder(id) {
                return this.$store.getters['order/getById'](id);
            },

            async onDispatchSelected() {
                this.isSaving = true;

                try {
                    await this.$store.dispatch('shipments/dispatch', {
                        shipments: this.shipmentsToDispatch
                    });

                    this.step = DispatchStep.Complete;
                }
                finally {
                    this.isSaving = false;

                    this.$emit('dispatched');
                }
            },

            onBackSelected() {
                this.active = false;
            }
        }
    };
</script>

<style>
    .extra-large {
        width: 256px;
        height: 256px;
        font-size: 12em !important;
    }
</style>
